import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Route,
  UrlSegment,
  CanLoad,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Web3Service } from '../services/web3.service';

@Injectable()
export class AdminGuard implements CanActivate, CanLoad {
  constructor(
    private auth: AuthService,
    private location: Location,
    private readonly web3: Web3Service
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return (
      this.auth.isAuthorized() &&
      !this.auth.isTokenExpired(this.auth.token) &&
      this.web3.isConnected &&
      this.auth.isAdmin()
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isTokenExpired(this.auth.token) || !this.web3.isConnected) {
      this.web3.logout();
      return false;
    }
    return true;
  }
}
