<section class="selector_project">
  <div class="container">

    <div class="project_claimer">
      <div class="project_claimer_top">
        <div class="project_claimer_img"><img src="{{lockInfo?.projectIcon}}" alt=""></div>
        <div class="project_claimer_info">
          <div class="t">{{lockInfo?.projectName}}</div>
          <p>{{lockInfo?.tokenAddress}}</p>
        </div>
        <div class="project_claimer_token project_claimer_token_ver2">
          <img src="{{lockInfo?.networkId | chainIcon}}" width="28" height="28" alt="">
          {{lockInfo?.networkId | chainName}}
        </div>
      </div>
      <div class="project_claimer_bott project_claimer_bott_ver2">
        <div class="project_claimer_bott_bb">
          <div class="project_claimer_bott_img"><img src="assets/images/clip.png" alt=""></div>
          <div class="project_claimer_bott_info">
            <div class="t">{{lockedBalance | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenName}}</div>
            <p>{{lockInfo?.tokenSymbol}} Locked</p>
          </div>
        </div>
        <div class="project_claimer_bott_progress">
          <label for="progress" [ngStyle]="{'margin-left': (claimedPercent > 14 ? claimedPercent-14 + '%' : 0)}">{{claimedPercent}}% claimed</label>
          <progress id="progress" max="100" [value]="claimedPercent"></progress>
          <label for="progress" [ngStyle]="{'margin-left': (claimedPercent >= 93 ? claimedPercent-10 : claimedPercent) + '%'}">{{100-claimedPercent}}% left</label>
        </div>
      </div>
    </div>

    <div class="summary_set">
      <div class="setting_user">
        <div class="setting_user_item setting_user_item_summary">
          <div class="tabs_select">
            <div class="t summary_btn">Summary <div class="summary_btn_on"><img src="images/prev.png" alt=""></div>
            </div>
            <div class="table_token_found table_token_found_filter">
              <div>
                <p>Blockchain</p> <span><img src="{{lockInfo?.networkId | chainIcon}}" width="28" height="28" alt="">
                  {{lockInfo?.networkId | chainName}}</span>
              </div>
            </div>

            <div class="table_token_found table_token_found_filter">
              <div>
                <p>Token Address</p> <span>{{lockInfo?.tokenAddress}}</span>
              </div>
              <div>
                <p>Contract Address</p> <span>{{lockInfo?.contractAddress}}</span>
              </div>
            </div>

            <div class="table_token_found table_token_found_filter">
              <div>
                <p>Token Name</p> <span>{{lockInfo?.tokenName}}</span>
              </div>
              <div>
                <p>Token Ticker</p> <span>{{lockInfo?.tokenSymbol}}</span>
              </div>
            </div>

            <div class="table_token_found table_token_found_filter">

              <div>
                <p>Project Name</p> <span *ngIf="!editMode">{{lockInfo?.projectName}} </span> <span *ngIf="editMode"><input [(ngModel)]="newProjectName"/></span>
              </div>
              <div>
                <p>Project Icon</p>
                <span>
                  <img src="{{lockInfo?.projectIcon}}" alt="" *ngIf="!editMode" width="48" height="48">
                  <img [src]="newProjectIcon ? newProjectIcon : '/assets/images/Image-icon.png'" width="48" height="48" *ngIf="editMode" alt="">
                  <button *ngIf="editMode" (click)="icon.click()">
                    <img src="assets/images/Upload-icon.png" alt="">Change Icon
                  </button>
                  <input #icon style="display: none;" (change)="onProjectIconSelected($event)" type="file" />
                </span>
              </div>
              <div>
                <p>Project hidden</p>
                <span class="span_checkbox">
                  <input type="checkbox" [disabled]="true" *ngIf="!editMode" [checked]="lockInfo?.isHidden"/>
                  <input type="checkbox" *ngIf="editMode" [(ngModel)]="isHidden"/>
                </span>
              </div>
              <div>
                <button *ngIf="!editMode" (click)="edit()">EDIT <img src="assets/images/Edit.png" alt=""></button>
                <button *ngIf="editMode" (click)="save()">SAVE <img src="assets/images/end.png" alt=""></button>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="right_form_set">

        <!-- <div class="calendae_blok">
          <div class="t">Distribution Calendar</div>

        </div> -->

        <!-- <div class="dist_list">
          <div class="t">Distribution List</div>
          <ul>
                      <li>
                          <div class="dist_list_l">
                              <p>03.10.2022</p>
                              <span>13:10:10 UTC</span>
                          </div>
                          <div class="dist_list_r">200,000 BLP</div>
                      </li>

                      <li>
                          <div class="dist_list_l">
                              <p>03.10.2022</p>
                              <span>13:10:10 UTC</span>
                          </div>
                          <div class="dist_list_r">200,000 BLP</div>
                      </li>

                      <li>
                          <div class="dist_list_l">
                              <p>03.10.2022</p>
                              <span>13:10:10 UTC</span>
                          </div>
                          <div class="dist_list_r">200,000 BLP</div>
                      </li>

                      <li>
                          <div class="dist_list_l">
                              <p>03.10.2022</p>
                              <span>13:10:10 UTC</span>
                          </div>
                          <div class="dist_list_r">200,000 BLP</div>
                      </li>

                      <li>
                          <div class="dist_list_l">
                              <p>03.10.2022</p>
                              <span>13:10:10 UTC</span>
                          </div>
                          <div class="dist_list_r">200,000 BLP</div>
                      </li>
                  </ul>
          <button class="add_info">Add Distribution Info</button>
        </div> -->

        <div class="dist_list">
          <div class="t">Deposit Funds</div>
          <div style="display: inline-flex; height: 30px">
            <input [ngModel]="depositValue | shiftDecimals: -lockInfo?.tokenDecimals" [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="calculateDepositBalance($event)"/>
            <button class="add_info max" style="margin-top: 0px; height: 30px; border: 0px;"  (click)="setMaxDepositValue()">MAX</button>
          </div>
          <button class="add_info deposit" (click)="depositFunds()">Deposit</button>
        </div>

        <div class="dist_list" *ngIf="lockInfo?.version === 2">
          <div class="t">Export Refund Requests</div>
          <button class="add_info withdraw" (click)="exportRefundRequests()">Export</button>
        </div>

        <!-- <div class="dist_list" *ngIf="!isStopped && lockedBalance">
          <div class="t">Emergency Withdraw</div>
          <button class="add_info withdraw" (click)="emergencyWithdraw()">Withdraw</button>
        </div> -->

        <div class="dist_list">
          <div class="t">Stop Vesting</div>
          <button class="add_info withdraw" (click)="stopVesting()">Stop</button>
        </div>
      </div>
    </div>
  </div>
</section>
