<footer>
    <div class="container">
        <div class="footer_left">
            <div class="logos">
              <div class="logo-item Incubat3-logo" [ngClass]="{ active: activeLogo === 'Incubat3' }">
                <img src="assets/images/Incubat3.svg" alt="Incubat3-logo">
              </div>
            </div>
            <div class="project">©2024 Incubat3. All rights reserved.</div>
        </div>

        <div class="footer-right" data-da=".footer_left,1100,1">
            <div class="social-links-wrapper">
              <div class="social">
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/globe-icon.png" alt="globe">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/linkedin-icon.png" alt="linkedin">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/twitter-icon.png" alt="twitter">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/telegram-icon.png" alt="telegram">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/youtube-icon.png" alt="youtube">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/medium-icon.png" alt="medium">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/facebook-icon.png" alt="facebook">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/instagram-icon.png" alt="instagram">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/reddit-icon.png" alt="reddit">
                </a>
                <a href="#" target="_blank" class="social-item">
                  <img src="assets/images/discord-icon.png" alt="discord">
                </a>
              </div>

              <!-- <div class="social" *ngIf="activeLogo === 'gamespad'">
                <a href="https://gamespad.io/" target="_blank" class="social-item">
                  <img src="assets/images/globe-icon.png" alt="globe">
                </a>
                <a href="https://www.linkedin.com/company/gamespad/" target="_blank" class="social-item">
                  <img src="assets/images/linkedin-icon.png" alt="linkedin">
                </a>
                <a href="https://www.twitch.tv/gamespad_vc" target="_blank" class="social-item">
                  <img src="assets/images/twitter-icon.png" alt="twitter">
                </a>
                <a href="https://t.me/GamesPadChat" target="_blank" class="social-item">
                  <img src="assets/images/telegram-icon.png" alt="telegram">
                </a>
                <a href="https://www.youtube.com/channel/UCgl9viZgL3_07spR8ObFiqQ" target="_blank" class="social-item">
                  <img src="assets/images/youtube-icon.png" alt="youtube">
                </a>
                <a href="https://medium.com/gamespad" target="_blank" class="social-item">
                  <img src="assets/images/medium-icon.png" alt="medium">
                </a>
                <a href="https://www.facebook.com/gamespad.vc" target="_blank" class="social-item">
                  <img src="assets/images/facebook-icon.png" alt="facebook">
                </a>
                <a href="https://www.instagram.com/gamespad.io/" target="_blank" class="social-item">
                  <img src="assets/images/instagram-icon.png" alt="instagram">
                </a>
                <a href="https://www.reddit.com/r/GamesPad_VC/" target="_blank" class="social-item">
                  <img src="assets/images/reddit-icon.png" alt="reddit">
                </a>
                <a href="https://discord.com/invite/xKZxT3fX44" target="_blank" class="social-item">
                  <img src="assets/images/discord-icon.png" alt="discord">
                </a>
              </div> -->
            </div>

            <div class="policy">
              <!-- <a href="https://github.com/solidproof/projects/tree/main/Bullperks" target="_blank">Audits</a> -->
              <a href="/documents/privacy-policy" target="_blank">Privacy Policy</a>
              <a href="/documents/cookie-policy" target="_blank">Cookie Policy</a>
              <a href="/documents/terms-of-condition" target="_blank">Terms Of Condition</a>
            </div>
        </div>
    </div>
</footer>
