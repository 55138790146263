import {Injectable, NgZone} from "@angular/core";
import {Web3Service} from "./web3.service";
import {AuthService} from "./auth.service";
import {AlertService} from "./alert.service";
import Web3 from "web3";
import {WalletProviders} from "../models/wallet/wallet-providers.enum";
import {environment} from "src/environments/environment";
import {ExtensionProvider} from "@elrondnetwork/erdjs-extension-provider/out";
import {Address, SignableMessage} from "@elrondnetwork/erdjs/out";
import { ElrondService } from "./elrond.service";

declare let window: any;

@Injectable({ providedIn: 'root' })
export class WalletConnectorService {
  private providerName: string;

  constructor(
    private readonly web3Service: Web3Service,
    private alertService: AlertService,
    private readonly elrond: ElrondService,
    private readonly auth: AuthService,
    private readonly ngZone: NgZone,
  ) { }

  public async connectWallet(walletProvider: WalletProviders) {
    switch (walletProvider) {
      case WalletProviders.Metamask:
        await this.connectMetamask();
        break;
      case WalletProviders.WalletConnect:
        await this.connectWalletConnect();
        break;
      default:
        console.error('Unsupported wallet type');
    }
  }

  private async connectMaiar(): Promise<void>{
    const provider = ExtensionProvider.getInstance();
    await provider.init();

    const address = await provider.login();

    console.log(provider.account);
    console.log(provider);
    this.elrond.initWallet(provider.account.address);

    //await this.signMessageForAuth(WalletProviders.Maiar, null, provider);
    this.auth.authenticateElrond();
  }

  private async connectMetamask(): Promise<void> {
    if (typeof window.ethereum == 'undefined') {
      this.alertService.show('MetaMask must be installed');
      return;
    }
    const accountAddressPromise = window.ethereum.request({ method: 'eth_requestAccounts' });
    const networkPromise = window.ethereum.request({ method: 'eth_chainId' });

    await Promise.all([accountAddressPromise, networkPromise]).then(([account, network]) => {
      this.web3Service.initWallet(account[0], network);
    });

    await this.signMessageForAuth('metamask');
  }

  private async connectWalletConnect() {
    const data: any = await this.WalletConnect();
    const chainIdDecimal = await this.web3Service.web3Instance.eth.getChainId();
    const chainId = `0x${chainIdDecimal.toString(16)}`;
    this.web3Service.initWallet(data[0], chainId, WalletProviders.WalletConnect);
    var dialog = this.alertService.show(`Please open "${this.providerName}" app and sign message.`);
    await this.signMessageForAuth('walletConnect');
    dialog.close();
  }


  private async signMessageForAuth(type: string): Promise<void> {
    const timeStamp = Math.floor(Date.now() / 1000);
    let authData;
    let signedMessage;
    if (type == 'metamask' || type == 'walletConnect') {
      const signature = await this.web3Service.personalSign(timeStamp.toString(), this.web3Service.currentAccountValue);
      authData = {
        timeStamp,
        ethAddress: this.web3Service.currentAccountValue,
        signature,
      }
    }
    if (!authData.signature) {
      console.log('Not signed!');
      return;
    }
    await this.auth.authenticate(authData);
    location.reload();
  }


  async WalletConnect(): Promise<string[]> {
    //  Create WalletConnect Provider
    const provider = this.web3Service.createWalletConnectProvider();

    //  Enable session (triggers QR Code modal)
    let addresses = await provider.enable();
    this.providerName = provider.connector.peerMeta.name;

    //  Create Web3
    this.web3Service.web3Instance = new Web3(provider as any);

    return addresses;
  }

}
