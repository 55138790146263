<section class="selector_project">
  <div class="container">

    <div class="project_claimer">
      <div class="project_claimer_top">
        <div class="project_claimer_img"><img src="{{lockInfo?.projectIcon}}" alt=""></div>
        <div class="project_claimer_info">
          <div class="t" data-da=".project_claimer_img,1199,1">{{lockInfo?.projectName}}</div>
          <p> <span class="token-address-wrapper" (click)="copyAddress()"><img
                src="../../../assets/images/copy-to-clipboard-icon.svg" alt="copy">{{lockInfo?.tokenAddress}}</span>
            <span (click)="addTokenToMetamask()"><img src="assets/images/image 1.png" alt="">
            </span>
          </p>
        </div>
        <div class="project_claimer_token_wrapper">
          <div class="project_claimer_token">
            <div class="project_claimer_token_item">
              <img src="{{lockInfo?.networkId | chainIcon}}" width="28" height="28" alt="">
              {{lockInfo?.networkId | chainName}}
            </div>

            <!-- <div class="project_claimer_token_item">
              <img src="{{lockInfo?.platform.icon}}" alt="">
              {{lockInfo?.platform.name}}
            </div> -->
          </div>
          <div (click)="alertCantClaim()" class="project_claimer_info_cant_claim">
            <img src="../../../assets/images/info.png">
            Can't claim?
          </div>
        </div>
      </div>
      <div class="project_claimer_bott" *ngIf="isAvailable">
        <div class="project_claimer_bott_info">
          <div class="project_claimer_left">
            <div class="token-claimed">
              <p class="amount">{{claimedAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}
              </p>
              <p class="text">Claimed</p>
            </div>
            <div class="divider">/</div>
            <div class="token-all-allocation">
              <p class="amount">{{totalAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}</p>
              <p class="text">All allocation</p>
            </div>
          </div>

          <div class="project_claimer_right">
            <div class="token-available-to-claim">
              <p class="amount">{{isRefunded ? 0 : lockInfo.availableToClaim | shiftDecimals: -lockInfo?.tokenDecimals}}
                {{lockInfo?.tokenSymbol}}</p>
              <p class="text">Claimable</p>
            </div>
            <div class="divider">/</div>
            <div class="token-left">
              <p class="amount">{{leftAmount | shiftDecimals: -lockInfo?.tokenDecimals}} {{lockInfo?.tokenSymbol}}</p>
              <p class="text">Left</p>
            </div>
          </div>
        </div>
        <div class="project_claimer_bott_progress">
          <progress max="100" [value]="claimedPercent"></progress>
        </div>
      </div>
    </div>

    <div class="project">
      <!-- <div class="project-top project-top_1fr">
              <div class="project-top-right">
                  <a href="#">All Locks</a>
                  <a href="#">My Locks</a>
                  <a href="#" class="act">Settings</a>
              </div>
          </div> -->

      <div *ngFor="let group of unlockGroups" class="all_clime">
        <div class="all_clime_item " [class.all_clime_item_period]="unlock.periodUnit && lockInfo.isUserParticipated"
          [class.all_clime_item_not_participated]="unlock.periodUnit && !lockInfo.isUserParticipated"
          *ngFor="let unlock of group" [ngClass]="{'project_activ': isConnected && canBeClaimed(unlock) && !unlock.isCanceled}">
          <!-- [ngClass]="{'all_clime_item_activ': unlockPassed(unlock.startDate), 'all_clime_item_end': false}" -->
          <div>
            <div class="t" *ngIf="unlock.percentage != 0">Vesting Starts</div>
            <div class="t" *ngIf="unlock.percentage == 0">Cliff Date</div>
            <p>{{unlock.startDate * 1000 | date:'dd MM YYYY HH:mm:ss':'+0000'}} UTC</p>
          </div>
          <div *ngIf="unlock.periodUnit">
            <div class="t">Vesting Ends</div>
            <p>{{unlock.endDate * 1000 | date:'dd MM YYYY HH:mm:ss':'+0000'}} UTC</p>
          </div>
          <div>
            <div class="t" *ngIf="unlock.percentage != 0">Claim percent</div>
            <div class="t" *ngIf="unlock.percentage == 0">---</div>
            <p *ngIf="unlock.percentage != 0 && (!unlock.periodUnit || !lockInfo.isUserParticipated)">
              {{unlock.percentage / evmPercentageDecimal}} % </p>
                <p *ngIf="unlock.percentage != 0 && (unlock.periodUnit && lockInfo.isUserParticipated)">
                  {{unlock.percentage / evmPercentageDecimal / ((unlock.endDate - unlock.startDate +
                    unlock.periodUnit) / unlock.periodUnit) | number:'1.0-2'}} % </p>
          </div>
          <div *ngIf="unlock.periodUnit && lockInfo.isUserParticipated">
            <div class="t">Claimed</div>
            <p>
              {{calculateClaimed(unlock)}}% / {{unlock.percentage / evmPercentageDecimal}} % </p>
          </div>
          <div *ngIf="isAvailable && !isRefunded"
            (click)="(canBeClaimed(unlock) || unlock.canClaim) ? claimToken(lockInfo, unlock) : false"
            style="color:black">
            <a *ngIf="isAirdropped(unlock)">Airdropped</a>
            <!-- <a *ngIf="!lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isClaimed">{{unlock.startDate*1000 | countdown | async}}</a> -->
            <a
              *ngIf="!lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isCanceled && !unlock.isClaimed && unlock.timeLeft > 0 && !unlock.canClaim && (vestingStoppedTime <= 0 || !vestingStoppedTime)">
              <cd-timer [countdown]="true" [startTime]="unlock.timeLeft"
                (onComplete)="handleCountDownEndsEvent($event, unlock)"></cd-timer>
            </a>
            <a *ngIf="lockInfo.isStopped && !isAirdropped(unlock) && unlock.percentage != 0 && !canBeClaimed(unlock) && !unlock.isClaimed && unlock.isCanceled"
              (click)="alertCanceled()">Canceled</a>
            <a *ngIf="canBeClaimed(unlock) || unlock.canClaim" class="btn_claim">Claim all</a>
            <a *ngIf="!isAirdropped(unlock) && unlock.percentage != 0 && unlock.isClaimed">Claimed</a>
          </div>
          <a *ngIf="!isConnected" class="connect_wallet" (click)="connectWallet()">Connect Wallet</a>
          <a *ngIf="isRefunded">Refunded</a>
        </div>
      </div>

      <div class="request_refund" *ngIf="isAvailable && isRefundVisible(lockInfo)">
        <button [ngClass]="{ 'disable': isTokenClaimed() || isRefundRequested}" class="request_refund_btn"
          (click)="isTokenClaimed() ? showWarningPopup() : isRefundRequested ? null : requestRefund(lockInfo)">Request
          Refund</button>
      </div>

      <div class="receiving_wallet" *ngIf="isAvailable && claimedAmount != totalAmount && isRefundPast(lockInfo) && !isRefunded">
        <!--<div class="receiving_wallet_block">
                  <div class="t">Receiving Wallet</div>
                  <input type="text" placeholder="Enter new wallet here" [(ngModel)]="newWallet">
              </div>-->
        <button (click)="changeWallet(lockInfo)">Change Wallet</button>
      </div>
    </div>
  </div>
</section>
