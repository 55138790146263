<section class="tabs_block">
  <div class="container">
    <h2>Lock Process</h2>

    <div class="tabs_s">
      <div class="tabs_block_link">
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 1, 'is-open':step==1}">
          <div class="tabs_block_link_number">
            01
          </div>
          <div class="tabs_block_link_t">Select Blockchain</div>
        </div>
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 2, 'is-open':step==2}">
          <div class="tabs_block_link_number">
            02
          </div>
          <div class="tabs_block_link_t">Enter Your Address</div>
        </div>
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 3, 'is-open':step==3}">
          <div class="tabs_block_link_number">
            03
          </div>
          <div class="tabs_block_link_t">Confirm Your Token</div>
        </div>
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 4, 'is-open':step==4}">
          <div class="tabs_block_link_number">
            04
          </div>
          <div class="tabs_block_link_t">Project and Wallets</div>
        </div>
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 5, 'is-open':step==5}">
          <div class="tabs_block_link_number">
            05
          </div>
          <div class="tabs_block_link_t">Lock Amount and Vesting</div>
        </div>
        <div class="tabs_block_link_item" [ngClass]="{'end':step > 6, 'is-open':step==6}">
          <div class="tabs_block_link_number">
            06
          </div>
          <div class="tabs_block_link_t">Summary</div>
        </div>
      </div>

      <div>
        <div class="tabs_block_container">

          <!-- БЛОК 1 -->
          <div class="tabs_select" *ngIf="step == 1">
            <div class="t">Select Blockchain</div>
            <div *ngIf="isConnected" class="blockchain">
              <div class="blockchain_item" *ngIf="!isProduction">
                <input type="radio" name="blockchain" id="bnb_test" value="0" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="bnb_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/binance.png" alt="">BNB chain (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="bnb_main" value="4" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="bnb_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/binance.png" alt="">BNB chain
                  </div>
                </label>
              </div>

              <div class="blockchain_item" *ngIf="!isProduction">
                <input type="radio" name="blockchain" id="polygon_test" value="2" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="polygon_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/Polygon.png" alt="Polygon (Matic)">Polygon (test)
                  </div>
                </label>
              </div>
              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="polygon_main" value="6" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="polygon_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/Polygon.png" alt="Polygon (Matic)">Polygon
                  </div>
                </label>
              </div>

              <div class="blockchain_item" *ngIf="!isProduction">
                <input type="radio" name="blockchain" id="avalanche_test" value="3" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="avalanche_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/Avalanche.png" width="28" height="28" alt="Avalanche">Avalanche (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="avalanche_main" value="7" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="avalanche_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/Avalanche.png" width="28" height="28" alt="Avalanche">Avalanche
                  </div>
                </label>
              </div>

              <div class="blockchain_item" *ngIf="!isProduction">
                <input type="radio" name="blockchain" id="eth_test" value="1" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="eth_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/eth.png" alt="ETH">ETH (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="eth_main" value="5" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="eth_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/eth.png" alt="ETH">ETH
                  </div>
                </label>
              </div>
              <div class="blockchain_item" *ngIf="!isProduction">
                <input type="radio" name="blockchain" id="kcc_test" value="8" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="kcc_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/kcc.png" height="28"  alt="KCC_test"> KCC (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="kcc_main" value="9" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="kcc_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/kcc.png" height="28" alt="KCC"> KCC
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="arbitrum_test" value="11" [(ngModel)]="network"
                       (ngModelChange)="changeAccountType()">
                <label for="arbitrum_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/arbitrum.png" height="28" alt="Arbitrum (test)"> Arbitrum (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="arbitrum_main" value="10" [(ngModel)]="network"
                       (ngModelChange)="changeAccountType()">
                <label for="arbitrum_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/arbitrum.png" height="28" alt="Arbitrum"> Arbitrum
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="base_test" value="13" [(ngModel)]="network"
                       (ngModelChange)="changeAccountType()">
                <label for="base_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/base.png" height="28" alt="Base (test)"> Base (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="base_main" value="12" [(ngModel)]="network"
                       (ngModelChange)="changeAccountType()">
                <label for="base_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/base.png" height="28" alt="Base"> Base
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="blast_test" value="15" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="blast_test">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/blast.png" height="28" alt="Blast (test)"> Blast (test)
                  </div>
                </label>
              </div>

              <div class="blockchain_item">
                <input type="radio" name="blockchain" id="blast_main" value="14" [(ngModel)]="network"
                  (ngModelChange)="changeAccountType()">
                <label for="blast_main">
                  <div class="blockchain_item_c">
                    <img src="/assets/images/blast.png" height="28" alt="Blast"> Blast
                  </div>
                </label>
              </div>

            </div>
          </div>
          <!-- БЛОК 1 -->


          <!-- БЛОК 2-->
          <div class="tabs_select" *ngIf="step == 2">
            <div class="t">Enter Your Address</div>
            <div class="enter_address">
              <div class="enter_address_item">
                <label for="enter_address">Enter your token address you would like to lock for</label>
                <input type="text" name="enter_address" id="enter_address" [(ngModel)]="tokenAddress">
                <div *ngIf="isConnected" class="enter_address_example">Example: 0x23EC58e45ac5313BCB6681F4f7827B8a8453AC45</div>
              </div>
            </div>
            <div class="platform_d">
              <label for="platforms" class="platforms_l">Platform</label>
              <ngx-select [items]="availableProject | async" id="platforms" [(ngModel)]="projectId"
                (selectionChanges)="platformSelected($event)" placeholder="Select project">
                <ng-template ngx-select-option let-option let-text="text">
                  <img class="dd-option-image" src="{{option.data.icon}}">
                  <label style="margin-bottom: 0px;" class="dd-option-text">{{option.data.name}}</label>
                  <!-- <span class="color-box" [style]="style('background-color:' + option.value)"></span>
                                    <span [innerHtml]="text"></span> -->
                  <!-- ({{option}}) -->
                </ng-template>

                <ng-template ngx-select-option-selected let-option let-text="text">
                  <img class="dd-option-image" src="{{option.data.icon}}">
                  <label style="margin-bottom: 0px;" class="dd-option-text">{{option.data.name}}</label>
                </ng-template>
              </ngx-select>

              <!-- <select  name="">
                                <option value="BullPerks" data-imagesrc="/assets/images/bullperks.png">BullPerks</option>
                                <option value="BNB" data-imagesrc="/assets/images/bnb.png"> BNB</option>
                                <option value="BNB2" data-imagesrc="/assets/images/bullperks.png"> BNB2</option>
                                <option value="BNB3" data-imagesrc="/assets/images/bullperks.png"> BNB3</option>
                            </select> -->
            </div>

            <div *ngIf="tokenLoading">

              <div class="icons_popap wait_animation"><img src="assets/images/wait.png" alt=""></div>
              <div class="wait_info">Please, wait...</div>

            </div>
          </div>
          <!-- БЛОК 2 -->


          <!-- БЛОК 3 -->
          <div class="tabs_select_lock" *ngIf="step == 3">
            <div class="tabs_select">
              <div class="t">Confirm Your Token</div>
              <div class="enter_address">
                <div class="enter_address_item">
                  <label for="enter_address enter_address_opaciti">Enter your token address you would like to lock
                    for</label>
                  <input type="text" name="enter_address" disabled id="enter_address" value="{{tokenAddress}}">
                </div>
              </div>
            </div>

            <div class="tabs_select">
              <div class="t">Token Found</div>
              <div class="table_token_found">
                <div>
                  <p>Token Name</p> <span>{{tokenInfo.tokenName}}</span>
                </div>
                <div>
                  <p>Token Symbol</p> <span>{{tokenInfo.tokenSymbol}}</span>
                </div>
                <div>
                  <p>Total Supply</p> <span>{{tokenInfo.totalSupply | shiftDecimals: -tokenInfo.tokenDecimal}}</span>
                </div>
                <div>
                  <p>Your Balance</p> <span>{{tokenInfo.balance | shiftDecimals: -tokenInfo.tokenDecimal}}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- БЛОК 3 -->


          <!-- БЛОК 4 -->
          <!-- <div class="tabs_select" *ngIf="step == 4">
                        <div class="t">Locking Token For</div>
                        <div class="token_for">
                            <div class="token_for_name">
                                <input type="text" placeholder="Project Name">
                            </div>
                            <div class="token_for_icon">
                                <div class="token_for_icon_img">
                                    <img [src]="projectUrl ? projectUrl : '/assets/images/Image-icon.png'" alt="">
                                </div>
                                <button (click)="icon.click()">
                                    <img src="/assets/images/Upload-icon.png" alt=""> Project Icon (1:1)
                                </button>
                                <input #icon style="display: none;" (change)="onProjectIconSelected($event)" type="file" />
                            </div>
                        </div>

                        <div class="t mt60">Claiming Wallets</div>
                        <div id="drop_file_zone" *ngIf="!claimingWallets">
                            <div id="drag_upload_file">
                                <img src="/assets/images/File.png" alt="">
                                <p>Upload wallets list</p>
                                <button (click)="wallets.click()"><img src="/assets/images/Upload-icon.png" alt=""> Upload File (.csv)</button>
                                <input #wallets id="selectfile" (change)="onWalletsFileSelected($event)" type="file" />
                            </div>
                        </div>

                    </div> -->
          <!-- БЛОК 4 -->


          <!-- БЛОК 5 -->
          <div class="tabs_select" *ngIf="step == 4">
            <div class="t">Locking Token For</div>
            <div class="token_for">
              <div class="token_for_name">
                <input type="text" placeholder="Project Name" [(ngModel)]="projectName">
              </div>
              <div class="token_for_icon">
                <div class="token_for_icon_img">
                  <img [src]="projectUrl ? projectUrl : '/assets/images/Image-icon.png'" alt="">
                </div>
                <button (click)="icon.click()">
                  <img src="/assets/images/Upload-icon.png" alt=""> Project Icon (1:1)
                </button>
                <input #icon style="display: none;" (change)="onProjectIconSelected($event)" type="file" />
              </div>
            </div>

            <!-- <div class="t mt60">Claiming Wallets</div>
                        <div id="drop_file_zone" *ngIf="!claimingWallets">
                            <div id="drag_upload_file">
                                <img src="/assets/images/File.png" alt="">
                                <p>Upload wallets list</p>
                                <button (click)="wallets.click()"><img src="/assets/images/Upload-icon.png" alt=""> Upload File (.csv)</button>
                                <input #wallets id="selectfile" (change)="onWalletsFileSelected($event)" type="file" />
                            </div>
                        </div> -->

          </div>

          <div class="tabs_select_lock" *ngIf="step == 4 ">
            <!-- <div class="tabs_select">
                            <div class="t">Locking Token For</div>
                            <div class="token_for2">
                                <div class="token_for_name">
                                    <input type="text" placeholder="Project Name">
                                </div>
                                <div class="token_for_bottom">
                                    <div class="token_for_icon_xls">
                                        <img src="/assets/images/xls_icon.png" alt="">
                                        bp-wallets.xls
                                        <div class="token_for_icon_xls_del"><img src="/assets/images/Close.png" alt=""></div>
                                    </div>
                                    <div class="token_for_icon">
                                        <div class="token_for_icon_img"><img src="/assets/images/Image-icon.png" alt=""></div>
                                        <button><img src="/assets/images/Upload-icon.png" alt=""> Project Icon (1:1)</button>
                                    </div>
                                    <div class="token_for_icon">
                                        <div class="token_for_icon_img">
                                            <img [src]="projectUrl ? projectUrl : '/assets/images/Image-icon.png'" alt="">
                                        </div>
                                        <button (click)="icon.click()">
                                            <img src="/assets/images/Upload-icon.png" alt=""> Project Icon (1:1)
                                        </button>
                                        <input #icon style="display: none;" (change)="onProjectIconSelected($event)" type="file" />
                                    </div>
                                </div>
                            </div>
                        </div> -->

            <div class="tabs_select">
              <div class="t">Claiming Wallets</div>
              <div class="drop_file_zone" *ngIf="!claimingWallets">
                <div class="drag_upload_file">
                  <img src="/assets/images/File.png" alt="">
                  <p>Upload wallets list</p>
                  <button (click)="wallets.click()"><img src="/assets/images/Upload-icon.png" alt=""> Upload File
                    (.csv)</button>
                  <input #wallets class="selectfile" (change)="onWalletsFileSelected($event)" type="file" />
                </div>
              </div>
              <div class="wallets_xls_file" *ngIf="claimingWallets">
                <img src="/assets/images/del_write.png" alt="">
                {{fileName}}
                <div class="wallets_xls_file_del" (click)="deleteFile()"><img src="/assets/images/del-red.png" alt="">
                </div>
              </div>

              <!-- <div class="new_wallet">
                                <input type="text" placeholder="Add New Wallet Address">
                                <input type="text" placeholder="Amount">
                                <button><img src="/assets/images/Close.png" alt=""> Add</button>
                            </div> -->

              <h5 *ngIf="claimingWallets">{{claimingWallets.length}} Wallets</h5>

              <div class="search_wallet" *ngIf="claimingWallets">
                <!-- <div class="search_wallet_top">
                                    <input type="text" placeholder="Search Wallet/BLP Value">
                                    <img src="/assets/images/Search.png" alt="">
                                </div> -->

                <div class="search_wallet_nav">
                  <div class="search_wallet_info">{{(claimingWallets|filter:'selected').length }} Selected</div>
                  <div class="search_wallet_btn">
                    <button (click)="selectAll()"><img src="/assets/images/select.png">Select All</button>
                    <button (click)="unselect()"><img src="/assets/images/deselect.png">Deselect</button>
                    <button (click)="delete()"><img src="/assets/images/del_w.png">Delete</button>
                  </div>
                </div>

                <div class="search_wallet_buttom">
                  <div class="search_wallet_b_item"
                    *ngFor="let walletInfo of claimingWallets | slice: (page-1) * pageSize : page * pageSize">
                    <label [ngClass]="{'selected':walletInfo.selected}">
                      <div class="search_wallet_b_content" (click)="walletInfo.selected = !walletInfo.selected">
                        <p>{{walletInfo.address}}</p>
                        <span>{{walletInfo.amount | shiftDecimals: -tokenInfo.tokenDecimal}}
                          {{tokenInfo.tokenSymbol}}</span>
                      </div>
                      <input [checked]="walletInfo.selected" type="checkbox">
                      <div></div>
                    </label>
                    <!--<div class="detal_saerch">
                      <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                       <div class="detal_saerch_btn">
                                                &lt;!&ndash;<button (click)="changeWallet(walletInfo)"><img src="/assets/images/Edit.png" alt="">Change Wallet</button>&ndash;&gt;
                                                <button (click)="removeWallet(walletInfo)"><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                    </div>-->
                  </div>

                  <!-- <div class="search_wallet_b_item">
                                        <label>
                                            <div class="search_wallet_b_content">
                                                <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                                <span>100 BLP</span>
                                            </div>
                                            <input type="checkbox">
                                            <div></div>
                                        </label>
                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Change Wallet</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search_wallet_b_item">
                                        <label>
                                            <div class="search_wallet_b_content">
                                                <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                                <span>100 BLP</span>
                                            </div>
                                            <input type="checkbox">
                                            <div></div>
                                        </label>
                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Change Wallet</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search_wallet_b_item">
                                        <label>
                                            <div class="search_wallet_b_content">
                                                <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                                <span>100 BLP</span>
                                            </div>
                                            <input type="checkbox">
                                            <div></div>
                                        </label>
                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search_wallet_b_item">
                                        <label>
                                            <div class="search_wallet_b_content">
                                                <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                                <span>100 BLP</span>
                                            </div>
                                            <input type="checkbox">
                                            <div></div>
                                        </label>
                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="search_wallet_b_item">
                                        <label>
                                            <div class="search_wallet_b_content">
                                                <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                                <span>100 BLP</span>
                                            </div>
                                            <input type="checkbox">
                                            <div></div>
                                        </label>
                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                                        </div>
                                    </div> -->
                </div>

                <div class="search_wallet_pagination">
                  <button [disabled]="page == 1" (click)="page = page-1"><img src="/assets/images/prev.png"
                      alt=""></button>
                  <!-- <a href="#">1</a>
                                    <a href="#">2</a>
                                    <a href="#">3</a> -->
                  <span>{{page}}/{{maxPage}}</span>
                  <!-- <a href="#">5</a>
                                    <a href="#">6</a> -->
                  <button class="search_wallet_pagination_next" [disabled]="page == maxPage"
                    (click)="page = page+1"><img src="/assets/images/prev.png" alt=""></button>
                </div>
              </div>
            </div>
          </div>
          <!-- БЛОК 5 -->



          <!-- БЛОК 7 -->
          <div class="tabs_select_lock" *ngIf="step == 5">
            <div class="tabs_select">
              <div class="t">Lock Amount</div>
              <div class="lock_amount">
                <div class="block_balance">
                  <div class="block_balance_item">
                    <div class="t">LockAmount (data)</div>
                    <input type="text" disabled [ngModel]="lockBalance | shiftDecimals: - tokenInfo?.tokenDecimal">
                  </div>

                  <div class="block_balance_item">
                    <div class="t">Lock Amount (deposit)</div>
                    <input type="text" [ngModel]="depositLock | shiftDecimals: -tokenInfo?.tokenDecimal"
                      [ngModelOptions]="{ updateOn: 'blur' }" (ngModelChange)="calculateLockBalance($event)"
                      name="inputField" type="text">
                  </div>
                  <button (click)="depositLock = lockBalance">MAX</button>
                </div>
              </div>
            </div>

            <div class="tabs_select">
              <div class="t">Vesting</div>
              <div class="vesting_set">
                <!-- <div class="vesting-left">
                  <div class="vesting-left_block">
                    <div class="t"> </div>
                    <select id="date_p" [(ngModel)]="vestingType">
                      <option [value]="1">Fixed</option>
                      <option [value]="2">Linear</option>
                    </select>
                  </div>

                </div> -->

                <div class="vesting-left">
                  <div>
                    <div class="t">Start date (for database)</div>
                    <mat-form-field floatLabel="never" class="date-time-picker">
                      <input matInput [ngxMatDatetimePicker]="startPicker" placeholder="Choose a date & time" [(ngModel)]="startDateDatePicker" (ngModelChange)="convertStarDateToTimestamp($event)">

                      <mat-datepicker-toggle matSuffix [for]="$any(startPicker)"></mat-datepicker-toggle>

                      <ngx-mat-datetime-picker #startPicker [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>

                  <div>
                    <div class="t">End date (for database)</div>
                    <mat-form-field floatLabel="never" class="date-time-picker">
                      <input matInput [ngxMatDatetimePicker]="endPicker" placeholder="Choose a date & time" [(ngModel)]="endDateDatePicker" (ngModelChange)="convertEndDateToTimestamp($event)">

                      <mat-datepicker-toggle matSuffix [for]="$any(endPicker)"></mat-datepicker-toggle>

                      <ngx-mat-datetime-picker #endPicker [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>
                </div>

                <div class="refund-right">
                  <div>
                    <div class="t">Refund deadline</div>
                    <mat-form-field floatLabel="never" class="date-time-picker">
                      <input matInput [ngxMatDatetimePicker]="refundPicker" placeholder="Choose a date & time" [(ngModel)]="dateTimePicker" (ngModelChange)="convertRefundDateToTimestamp($event)">

                      <mat-datepicker-toggle matSuffix [for]="$any(refundPicker)"></mat-datepicker-toggle>

                      <ngx-mat-datetime-picker #refundPicker [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>



                  <!--<div class="block_balance_item">
                    <div class="t">Refund deadline (in seconds)</div>
                    <input type="number" [(ngModel)]="refundDate">
                  </div>-->
                </div>
              </div>

              <!-- <div class="times_block">
                                <div class="times_b">
                                    <div class="times_b_t">Time</div>
                                    <div class="times_b_item">
                                        <input type="radio" class="input_rd" id="custop_time" name="times_1" >
                                        <label for="custop_time">
                                            <div class="times_b_item_input" >
                                                <div class="input_t">Time</div>
                                                <div class="times">
                                                    <input class="timepicker-ui-input" value="12:00 AM" />
                                                </div>
                                            </div>
                                        </label>

                                    </div>

                                    <div class="times_b_item">
                                        <input type="radio" id="custom" class="input_rd" name="times_1">
                                        <label for="custom">
                                            <div class="blockchain_item_c">Custom</div>
                                        </label>
                                    </div>
                                </div>

                                <div class="times_b">
                                    <div class="times_b_t">Daily Vesting</div>
                                    <div class="times_b_item">
                                        <input type="radio" class="input_rd" id="after_distr" name="times_2">
                                        <label for="after_distr">
                                            <div class="times_b_item_input">
                                                <div class="input_t">After Distribution</div>
                                                <div class="input_l">
                                                    <input class="timepicker-ui-input" placeholder="0" />
                                                </div>
                                            </div>
                                        </label>

                                    </div>

                                    <div class="times_b_item">
                                        <input type="radio" id="custom-no" class="input_rd" name="times_2">
                                        <label for="custom-no">
                                            <div class="blockchain_item_c">No</div>
                                        </label>
                                    </div>
                                </div>
                            </div> -->
            </div>

            <!-- <div class="tabs_select">
                            <div class="t">Cliff</div>
                            <div class="cliff_block">
                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item1" name="Cliff">
                                    <label for="cliff_item1">
                                        <div class="cliff_item_input">
                                            <div class="input_t">No</div>
                                            <p>Descrition of this option. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                        </div>
                                    </label>
                                </div>

                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item2" name="Cliff">
                                    <label for="cliff_item2">
                                        <div class="cliff_item_input">
                                            <div class="input_t">Immediately</div>
                                            <p>Descrition of this option. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                                        </div>
                                    </label>
                                </div>

                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item3" name="Cliff">
                                    <label for="cliff_item3">
                                        <div class="cliff_item_input">
                                            <div class="input_t">After Distribution</div>
                                            <div class="cliff_item_form">
                                                <div class="cliff_item_top">
                                                    <div class="t">Distribution Number</div>
                                                    <input type="text" placeholder="1">
                                                </div>
                                                <div class="cliff_item_bottom">
                                                    <div class="cliff_item_bottom_item">
                                                        <div class="t"></div>
                                                        <select id="cliff_p">
                                                            <option value="M">M</option>
                                                            <option value="Apr">Apr</option>
                                                            <option value="Nov">Nov</option>
                                                        </select>
                                                    </div>

                                                    <div class="cliff_item_bottom_item">
                                                        <div class="t">Duration (Months)</div>
                                                        <input type="text" name="" placeholder="1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div> -->

            <div class="tabs_select">
              <div class="t">{{unlocksNumber}} Unlocks (UTC) - for smart contract</div>
              <div class="table_unl">
                <div class="table_unl_item" *ngFor="let unlock of unlocks; let idx = index">
                  <div class="table_unl_n">
                    <span>{{idx+1}}</span>
                  </div>


                <!-- <div class="custom-control form-control-lg custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                    <label class="custom-control-label" for="customCheck1"></label>
                </div> -->

                  <div class="table_unl_input">
                    <div class="t">Is Period</div>
                    <div style="height: 32px">
                      <div class="custom-control form-control-lg custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="{{'isPeriod' + idx}}" [(ngModel)]="unlock.isPeriod" [disabled]="unlock.isUnlockedBeforeStart">
                        <label class="custom-control-label" for="{{'isPeriod' + idx}}"></label>
                      </div>
                    </div>
                  </div>

                  <div class="date-time-picker-wrapper">
                    <div class="t">Start date</div>
                    <mat-form-field floatLabel="never" class="date-time-picker">
                      <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date & time" [ngModel]="unlocksCopy[idx].startDate" (ngModelChange)="convertUnlockStartDateToTimestamp($event, idx)">

                      <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>

                      <ngx-mat-datetime-picker #picker [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>

                  <!--<div class="table_unl_input table_unl_input_date">
                    <div class="t">Start date (unix)</div>
                    <input class="data_input" type='text' [(ngModel)]="unlock.startDate" />
                  </div>-->

                  <div class="table_unl_input">
                    <div class="t">Tokens (%)</div>
                    <input type='text' [(ngModel)]="unlock.percentage" />
                  </div>

                  <div class="table_unl_input">
                    <div class="t">Airdropped</div>
                    <div style="height: 32px">
                      <div class="custom-control form-control-lg custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="{{'isUnlockedBeforeStart' + idx}}" [(ngModel)]="unlock.isUnlockedBeforeStart" [disabled]="unlock.isPeriod">
                        <label class="custom-control-label" for="{{'isUnlockedBeforeStart' + idx}}"></label>
                      </div>
                    </div>
                  </div>

                  <div class="detal_saerch" >
                    <div class="detal_saerch_btn_on" (click)="removeUnlock(idx)">
                      <img src="/assets/images/del_w.png">
                    </div>
                  </div>

                  <div class="table_unl_n">

                  </div>

                  <div class="table_unl_n">

                  </div>


                  <!--<div class="table_unl_input table_unl_input_date">
                    <div class="t">End date (unix)</div>
                    <input class="data_input" type='text'  [(ngModel)]="unlock.endDate" />
                  </div>-->

                  <div class="date-time-picker-wrapper" *ngIf="unlock.isPeriod">
                    <div class="t">End date</div>
                    <mat-form-field floatLabel="never" class="date-time-picker">
                      <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="Choose a date & time" [ngModel]="unlocksCopy[idx].endDate" (ngModelChange)="convertUnlockEndDateToTimestamp($event, idx)">

                      <mat-datepicker-toggle matSuffix [for]="$any(pickerEnd)"></mat-datepicker-toggle>

                      <ngx-mat-datetime-picker #pickerEnd [showSeconds]="true"></ngx-mat-datetime-picker>
                    </mat-form-field>
                  </div>

                  <div class="table_unl_input" *ngIf="unlock.isPeriod">
                    <div class="t">Period (in seconds)</div>
                    <div class="times1">
                      <input type="text" class="timepicker-ui-input" [(ngModel)]="unlock.periodUnit">
                    </div>
                  </div>






                </div>

                <!-- <div class="table_unl_item">
                                    <div class="table_unl_n">
                                        <span>02</span>
                                    </div>

                                    <div class="table_unl_input table_unl_input_date">
                                        <div class="t">Start date</div>
                                        <input class="data_input" type='text' />
                                    </div>

                                    <div class="table_unl_input">
                                        <div class="t">Time</div>
                                        <div class="times1">
                                            <input type="text" class="timepicker-ui-input" value="12:00 AM">
                                        </div>
                                    </div>

                                    <div class="table_unl_input">
                                        <div class="t">Tokens</div>
                                        <input type='text' />
                                    </div>

                                    <div class="detal_saerch">
                                        <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                        <div class="detal_saerch_btn">
                                            <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                            <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="table_unl_item">
                                    <div class="table_unl_n">
                                        <span>03</span>
                                    </div>

                                    <div class="table_unl_input table_unl_input_date">
                                        <div class="t">Start date</div>
                                        <input class="data_input" type='text' />
                                    </div>

                                    <div class="table_unl_input">
                                        <div class="t">Time</div>
                                        <div class="times1">
                                            <input type="text" class="timepicker-ui-input" value="12:00 AM">
                                        </div>
                                    </div>

                                    <div class="table_unl_input">
                                        <div class="t">Tokens</div>
                                        <input type='text' />
                                    </div>

                                    <div class="detal_saerch">
                                        <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                        <div class="detal_saerch_btn">
                                            <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                            <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                        </div>
                                    </div>
                                </div> -->

                <button class="unl_add" (click)="addUnlock()"><img src="/assets/images/nw_lock.png" alt="">Add
                  Unlock</button>

                <div class="drop_file_zone">
                  <div class="drag_upload_file">
                    <img src="/assets/images/File.png" alt="">
                    <p>Upload unlocks list</p>
                    <button (click)="unlock.click()"><img src="/assets/images/Upload-icon.png" alt=""> Upload File (.csv)</button>
                    <input #unlock class="selectfile" (change)="onUnlocksFileSelected($event)" type="file" />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="tabs_select">
                            <div class="tabs_select_result">
                                <div>
                                    <span>Total Locked</span>
                                    <p>{{lockBalance | shiftDecimals: -tokenInfo.tokenDecimal}} {{tokenInfo.tokenName}}</p>
                                </div>

                                <div>
                                    <span>Unlock Time</span>
                                    <p>Sat, Mar 12, 2023, 03:00:00 PM UTC</p>
                                </div>

                                <div>
                                    <span>Fees</span>
                                    <p>0.12</p>
                                </div>
                            </div>
                        </div> -->
          </div>
          <!-- БЛОК 7 -->

          <!-- БЛОК 6 -->
          <div class="tabs_select_lock" *ngIf="step == 6">
            <div class="tabs_select" *ngIf="!transactionProcessing">
              <div class="t">Summary</div>
              <div class="table_token_found table_token_found_filter">
                <div *ngIf="isConnected">
                  <p>Blockchain</p> <span><img src="/assets/images/binance32.png" alt=""> Binance</span>
                </div>
              </div>

              <div class="table_token_found table_token_found_filter">
                <div>
                  <p>Token Address</p> <span>{{tokenAddress}}</span>
                </div>
                <div>
                  <p>Project Icon</p><span><img src="{{project?.icon}}" class="icon_token" alt="">
                    {{project?.name}}</span>
                </div>
              </div>

              <div class="table_token_found table_token_found_filter">
                <div>
                  <p>Token Name</p> <span>{{tokenInfo.tokenName}}</span>
                </div>
                <div>
                  <p>Token Ticker</p> <span>{{tokenInfo.tokenSymbol}}</span>
                </div>
                <div>
                  <p>Total Supply</p> <span>{{tokenInfo.totalSupply | shiftDecimals: -tokenInfo.tokenDecimal}}</span>
                </div>
                <div>
                  <p>Your Balance</p> <span>{{tokenInfo.balance | shiftDecimals: -tokenInfo.tokenDecimal}}</span>
                </div>
              </div>

              <div class="table_token_found table_token_found_filter">
                <div>
                  <p>Project Name</p> <span>{{projectName}}</span>
                </div>
                <div>
                  <p>Project Icon</p>
                  <span>
                    <img src="/assets/images/bullperks.png" class="icon_token" alt="">
                    <!-- <button><img src="/assets/images/Upload-icon.png" alt="">Change Icon</button> -->
                  </span>
                </div>
                <div>
                  <p>Claiming Walets</p> <span>{{this.fileName}}</span>
                </div>
              </div>

              <div class="table_token_found table_token_found_filter">
                <div>
                  <p>Total sum in wallets</p> <span>{{lockBalance | shiftDecimals: -tokenInfo.tokenDecimal}}
                     {{tokenInfo.tokenSymbol}}</span>
                </div>
                <div>
                  <p>Amount to lock</p> <span>{{depositLock | shiftDecimals: -tokenInfo.tokenDecimal}}
                    {{tokenInfo.tokenSymbol}}</span>
                </div>
                <!-- <div>
                  <p>Vesting</p> <span>TDB</span>
                </div>
                <div>
                  <p>Locking Duration</p> <span>TBD</span>
                </div>
                <div>
                  <p>Unlcok Time</p> <span>TDB</span>
                </div>
                <div>
                  <p>Fees</p> <span>TDB</span>
                </div> -->
              </div>
            </div>

            <div *ngIf="transactionProcessing">

              <div class="icons_popap wait_animation"><img src="assets/images/wait.png" alt=""></div>
              <div class="wait_info">Please, wait...</div>

            </div>

            <!-- <div class="tabs_select">
                          <div class="t">Claiming Wallets</div>
                          <div class="wallets_xls_file">
                              <img src="/assets/images/del_write.png" alt="">
                              bp-wallets.xls
                              <div class="wallets_xls_file_del"><img src="/assets/images/del-red.png" alt=""></div>
                          </div>

                          <div class="new_wallet">
                              <input type="text" placeholder="Add New Wallet Address">
                              <button><img src="/assets/images/Close.png" alt=""> Add</button>
                          </div>

                          <h5>256 Wallets</h5>

                          <div class="search_wallet">
                              <div class="search_wallet_top">
                                  <input type="text" placeholder="Search Wallet/BLP Value">
                                  <img src="/assets/images/Search.png" alt="">
                              </div>

                              <div class="search_wallet_nav">
                                  <div class="search_wallet_info">2 Selected</div>
                                  <div class="search_wallet_btn">
                                      <button><img src="/assets/images/select.png" alt="">Select All</button>
                                      <button><img src="/assets/images/deselect.png" alt="">Deselect</button>
                                      <button><img src="/assets/images/del_w.png" alt="">Delete</button>
                                  </div>
                              </div>

                              <div class="search_wallet_buttom">
                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Change Wallet</button>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Change Wallet</button>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="search_wallet_b_item">
                                      <label>
                                          <div class="search_wallet_b_content">
                                              <p>0xfe1d7f7a8f0bda6e415593a2e4f82c64b446d404</p>
                                              <span>100 BLP</span>
                                          </div>
                                          <input type="checkbox">
                                          <div></div>
                                      </label>
                                      <div class="detal_saerch">
                                          <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                          <div class="detal_saerch_btn">
                                              <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                              <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="search_wallet_pagination">
                                  <button><img src="/assets/images/prev.png" alt=""></button>
                                  <a href="#">1</a>
                                  <a href="#">2</a>
                                  <a href="#">3</a>
                                  <span>4</span>
                                  <a href="#">5</a>
                                  <a href="#">6</a>
                                  <button class="search_wallet_pagination_next"><img src="/assets/images/prev.png" alt=""></button>
                              </div>
                          </div>
                      </div> -->
          </div>
          <!-- БЛОК 6 -->
          <div class="tabs_select_lock" *ngIf="step == 7 && !transactionProcessing" style="color: white;">
            Done
          </div>

          <!-- БЛОК 8 -->
          <!-- <div class="tabs_select_lock"*ngIf="step == 6">
                        <div class="tabs_select">
                            <div class="t">Lock Amount</div>
                            <div class="lock_amount">
                                <div class="block_balance">
                                    <div class="block_balance_item">
                                        <div class="t">Balance</div>
                                        <input type="text" placeholder="10,000,000 BLP">
                                    </div>

                                    <div class="block_balance_item">
                                        <div class="t">Lock Amount</div>
                                        <input type="text" placeholder="10,000,000 BLP">
                                    </div>

                                    <button>MAX</button>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_select">
                            <div class="t">Vesting</div>
                            <div class="vesting_set">
                                <div class="vesting-left">
                                    <div class="vesting-left_block">
                                        <div class="t"> </div>
                                        <select id="date_p2">
                                            <option value="Non-linear">Non-linear</option>
                                            <option value="Non-linear2">Non-linear2</option>
                                            <option value="Non-linear3">Non-linear3</option>
                                        </select>
                                    </div>

                                    <div class="vesting-left_block vesting-left_block_date">
                                        <div class="t">Start date</div>
                                        <input id="data_start" class="data_input" type='text' />
                                    </div>
                                </div>

                                <div class="vesting-left">
                                    <div class="vesting-left_block">
                                        <div class="t">Unlocks number</div>
                                        <input type="text" placeholder="" value="7">
                                    </div>

                                    <div class="vesting-left_block vesting-left_block_date">
                                        <div class="t">End date</div>
                                        <input id="data_end" class="data_input" type='text' />
                                    </div>
                                </div>

                                <div class="vesting-left">
                                    <div class="vesting-left_block">
                                        <div class="t">Start date</div>
                                        <div class="time_vesting">
                                            <input value="12:00 AM" class="timepicker-ui-input">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="daily_vesting">
                                <div class="t">Daily Vesting</div>
                                <div class="daily_vesting_block">
                                    <div class="daily_vesting_item daily_vesting_item_on">
                                        <input type="radio" id="daily_vesting1" class="input_rd" name="daily_vesting">
                                        <label for="daily_vesting1">
                                            <div class="daily_vesting_input">
                                                <div class="t">After Distribution</div>
                                                <input type="text" placeholder="0">
                                            </div>
                                        </label>
                                    </div>

                                    <div class="daily_vesting_item">
                                        <input type="radio" id="daily_vesting2" class="input_rd" name="daily_vesting">
                                        <label for="daily_vesting2">
                                            <div class="daily_vesting_input">
                                                <div class="t">Immediately</div>
                                            </div>
                                        </label>
                                    </div>

                                    <div class="daily_vesting_item">
                                        <input type="radio" id="daily_vesting3" class="input_rd" name="daily_vesting">
                                        <label for="daily_vesting3">
                                            <div class="daily_vesting_input">
                                                <div class="t">No</div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_select">
                            <div class="t">Cliff</div>
                            <div class="cliff_block">
                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item1" name="Cliff">
                                    <label for="cliff_item1">
                                        <div class="cliff_item_input">
                                            <div class="input_t">No</div>
                                            <p>Descrition of this option. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod.</p>
                                        </div>
                                    </label>
                                </div>

                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item2" name="Cliff">
                                    <label for="cliff_item2">
                                        <div class="cliff_item_input">
                                            <div class="input_t">Immediately</div>
                                            <p>Descrition of this option. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod.</p>
                                        </div>
                                    </label>
                                </div>

                                <div class="cliff_item">
                                    <input type="radio" class="input_rd" id="cliff_item3" name="Cliff">
                                    <label for="cliff_item3">
                                        <div class="cliff_item_input">
                                            <div class="input_t">After Distribution</div>
                                            <div class="cliff_item_form">
                                                <div class="cliff_item_top">
                                                    <div class="t">Distribution Number</div>
                                                    <input type="text" placeholder="1">
                                                </div>
                                                <div class="cliff_item_bottom">
                                                    <div class="cliff_item_bottom_item">
                                                        <div class="t"></div>
                                                        <select id="cliff_p">
                                                            <option value="M">M</option>
                                                            <option value="Apr">Apr</option>
                                                            <option value="Nov">Nov</option>
                                                        </select>
                                                    </div>

                                                    <div class="cliff_item_bottom_item">
                                                        <div class="t">Duration (Months)</div>
                                                        <input type="text" name="" placeholder="1">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="tabs_select">
                            <div class="t">7 Unlocks, Non-linear (UTC)</div>
                            <div class="table_unl">
                                <div class="table_unl_item_block">
                                    <div class="table_unl_item">
                                        <div class="table_unl_n">
                                            <span>01</span>
                                        </div>

                                        <div class="table_unl_input table_unl_input_date">
                                            <div class="t">Start date</div>
                                            <input class="data_input" type='text' />
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Time</div>
                                            <div class="times1">
                                                <input type="text" class="timepicker-ui-input" value="12:00 AM">
                                            </div>
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Tokens</div>
                                            <input type='text' />
                                        </div>
                                    </div>
                                    <div class="opps">1 month cliff after this distribution</div>
                                </div>

                                <div class="table_unl_item_block">
                                    <div class="table_unl_item disabled">
                                        <div class="table_unl_n">
                                            <span>02</span>
                                        </div>

                                        <div class="table_unl_input table_unl_input_date">
                                            <div class="t">Start date</div>
                                            <input class="data_input" type='text' />
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Time</div>
                                            <div class="times1">
                                                <input type="text" class="timepicker-ui-input" value="12:00 AM">
                                            </div>
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Tokens</div>
                                            <input type='text' />
                                        </div>

                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="table_unl_item_block">
                                    <div class="table_unl_item disabled">
                                        <div class="table_unl_n">
                                            <span>03</span>
                                        </div>

                                        <div class="table_unl_input table_unl_input_date">
                                            <div class="t">Start date</div>
                                            <input class="data_input" type='text' />
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Time</div>
                                            <div class="times1">
                                                <input type="text" class="timepicker-ui-input" value="12:00 AM">
                                            </div>
                                        </div>

                                        <div class="table_unl_input">
                                            <div class="t">Tokens</div>
                                            <input type='text' />
                                        </div>

                                        <div class="detal_saerch">
                                            <div class="detal_saerch_btn_on"><img src="/assets/images/detal.png" alt=""></div>
                                            <div class="detal_saerch_btn">
                                                <button><img src="/assets/images/Edit.png" alt="">Change Wallet</button>
                                                <button><img src="/assets/images/del_w.png" alt="">Remove</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button class="unl_add"><img src="/assets/images/nw_lock.png" alt="">Add Unlock</button>
                            </div>
                        </div>

                        <div class="tabs_select">
                            <div class="tabs_select_result">
                                <div>
                                    <span>Total Locked</span>
                                    <p>10,000,000 BLP</p>
                                </div>

                                <div>
                                    <span>Unlock Time</span>
                                    <p>Sat, Mar 12, 2023, 03:00:00 PM UTC</p>
                                </div>

                                <div>
                                    <span>Fees</span>
                                    <p>0.12</p>
                                </div>
                            </div>
                        </div>
                    </div> -->
          <!-- БЛОК 8 -->


          <!-- БЛОК 9-->
          <!-- <div class="tabs_select">
                        <div class="t">Vesting</div>
                        <div class="platform_d">
                            <select id="vesting_select" name="">
                                <option value="Custom">Custom</option>
                                <option value="BNB"> BNB</option>
                                <option value="BNB2"> BNB2</option>
                                <option value="BNB3"> BNB3</option>
                            </select>
                        </div>
                    </div> -->
          <!-- БЛОК 9 -->
        </div>


        <!-- 1 кнопка -->
        <div class="cviz_btn1" *ngIf="step == 7 && !transactionProcessing">
          <div class="done"><button (click)="goToMainPage()">Done</button></div>
        </div>
        <!-- 1 кнопка -->

        <div class="cviz_btn" *ngIf="step == 7 && !transactionProcessing">
          <div class="next"><button (click)="reset()">Create new lock</button></div>
        </div>

        <!-- 2 кнопки -->
        <div class="cviz_btn" *ngIf="!tokenLoading && !transactionProcessing && step != 7">
          <div class="back" (click)="backClick()"><button>Back</button></div>
          <div class="next" (click)="nextClick()"><button>Next</button></div>
        </div>
        <!-- 2 кнопки -->

        <!-- 3 кнопки -->
        <div class="cviz_btn2" style="display:none;">
          <div class="back"><button>Back</button></div>
          <div class="approve"><button><img src="/assets/images/apr.png" alt=""> Approve</button></div>
          <div class="lock"><button><img src="/assets/images/Lock2.png" alt=""> Lock</button></div>
        </div>
        <!-- 3 кнопки -->
      </div>
    </div>
  </div>
</section>
