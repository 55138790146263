import { IWalletType, WalletTypes } from './wallet-type.model';
import { WalletProviders } from './wallet-providers.enum';

export const typesToWallets: IWalletType[] = [
  {
    type: WalletTypes.EVM,
    wallets: [
      {
        name: WalletProviders.Metamask,
        icon: 'assets/images/image 1.png',
      },
      {
        name: WalletProviders.WalletConnect,
        icon: 'assets/images/WalletConnect.png',
      },
    ],
  },
];
