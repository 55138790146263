<section class="deploy_contract">
    <div class="container">
        <h2>Select Network</h2>
        <div class="blockchain blockchain_filter">
            <div class="blockchain_item">
                <input type="radio" name="blockchain" id="bsc" [checked]="networkID == 0">
                <label for="bsc" (click)="changeType(0)">
                    <div class="blockchain_item_c">BSC</div>
                </label>
            </div>

            <div class="blockchain_item">
                <input type="radio" name="blockchain" id="ethereum" [checked]="networkID == 1">
                <label for="ethereum" (click)="changeType(1)">
                    <div class="blockchain_item_c">Ethereum</div>
                </label>
            </div>

            <div class="blockchain_item">
                <input type="radio" name="blockchain" id="polygon" [checked]="networkID == 2">
                <label for="polygon" (click)="changeType(2)">
                    <div class="blockchain_item_c">Polygon</div>
                </label>
            </div>

            <div class="blockchain_item">
                <input type="radio" name="blockchain" id="avalanche" [checked]="networkID == 3">
                <label for="avalanche" (click)="changeType(3)">
                    <div class="blockchain_item_c">Avalanche</div>
                </label>
            </div>

        </div>
        <div class="deploy">
            <div class="deploy-button" (click)="test()">Deploy</div>
        </div>
    </div>
</section>
