import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { Web3Service } from 'src/app/services/web3.service';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { SelectWalletComponent } from '../dialogs/select-wallet/select-wallet.component';
import { WalletConnectorService } from '../../services/wallet-connector.service';
import { WalletProviders } from '../../models/wallet/wallet-providers.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public show: boolean = false;

  public get isNewLockAvailable(): boolean {
    return this.auth.isAdmin();
  }

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.show = false;
  }

  public currentAccount: Observable<string> = this.web3.currentAccount$;
  public currentNetwork: Observable<number> = this.web3.currentNetwork$.pipe(
    map((i) => parseInt(i, 16))
  );

  constructor(
    private readonly web3: Web3Service,
    private dialog: MatDialog,
    private readonly auth: AuthService,
    private readonly walletConnector: WalletConnectorService
  ) {}

  public async connectWallet(): Promise<void> {
    this.walletConnector.connectWallet(WalletProviders.Metamask);
  }

  public disconnect(): void {
    this.web3.logout();
  }

  public toggleNetworkChange(event: any): void {
    this.show = !this.show;
    event.stopPropagation();
  }

  public changeNetwork(id: number): void {
    this.web3.changeNetwork(id);
  }
}
