import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import BigNumber from 'bignumber.js';
import { LockDTO } from 'src/app/dto/lock.dto';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ClaimService } from 'src/app/services/claim.service';
import { PopupService } from 'src/app/services/popup.service';
import { Web3Service } from 'src/app/services/web3.service';
import { WalletConnectorService } from 'src/app/services/wallet-connector.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-edit-claiming',
  templateUrl: './edit-claiming.component.html',
  styleUrls: ['./edit-claiming.component.scss'],
})
export class EditClaimingComponent {
  public lockInfo: LockDTO;
  public lockedBalance: string;
  public claimedPercent: number = 0;
  public editMode: boolean = false;
  public isStopped: boolean = false;

  public projectId: number;

  public newProjectName: string;
  public newProjectIcon: string;
  public isHidden: boolean = null;
  public iconData: string;

  public depositValue: string;
  public setPause: boolean;

  public get isConnected(): boolean {
    return this.web3.isConnected && this.auth.isAuthorized();
  }

  constructor(
    private readonly web3: Web3Service,
    private readonly walletConnector: WalletConnectorService,
    private readonly auth: AuthService,
    private readonly api: ApiService,
    private readonly route: ActivatedRoute,
    private readonly popupService: PopupService,
    private readonly claimService: ClaimService,
    private papa: Papa
  ) {
    this.projectId = this.route.snapshot.params['id'];
    this.api.getLock(this.projectId).subscribe((i) => {
      this.lockInfo = i;
      this.getTokenAdditionalInfo();
    });
  }

  public async getTokenAdditionalInfo() {
    if (this.isConnected)
      this.lockedBalance = (
        await this.web3.getTokenInfo(
          this.lockInfo.tokenAddress,
          this.lockInfo.contractAddress
        )
      ).balance;
    const bnClaimedAmount = new BigNumber(this.lockedBalance);
    console.log('total vesting amount >>>>', this.lockInfo.amount);
    const bnTotalAmount = new BigNumber(this.lockInfo.amount);
    /*
      we need to 'assume', how much is claimed based on contract balance
      if balance zero it means all claimed (atm, in case of partial funds deposit), or nothing was deposited
    */
    const bnClaimedPecentage = bnTotalAmount
      .minus(bnClaimedAmount)
      .times(new BigNumber('100'))
      .div(bnTotalAmount);
    this.claimedPercent = Math.round(bnClaimedPecentage.toNumber());

    if (this.lockInfo.version >= 1 && this.isConnected) {
      const isStopped = await this.web3.getIfVestingStopped(
        this.lockInfo.version,
        this.lockInfo.contractAddress
      );
      this.isStopped = isStopped;
    }
  }

  public async stopVesting() {
    try {
      if (this.isConnected)
        await this.web3.stopVesting(this.lockInfo.contractAddress);
      this.popupService.successMessage(`Successfully Stopped Vesting`);
      this.isStopped = true;
    } catch (error) {
      this.popupService.errorMessage(`Failed to Stop Vesting`);
    }
  }

  public setMaxDepositValue(): void {
    const amount = this.lockInfo.claimingWallets
      .map((i) => i.amount as any)
      .reduce(
        (previousValue: string, currentValue: string) =>
          BigInt(previousValue) + BigInt(currentValue),
        BigInt(0)
      );
    this.depositValue = amount.toString().replace('n', '');
  }

  public calculateDepositBalance(value: string): void {
    if (!value) {
      this.depositValue = null;
    }
    this.depositValue = new BigNumber(value).shiftedBy(18).toString();
  }

  public edit(): void {
    this.editMode = true;
  }

  public async save(): Promise<void> {
    if (!this.newProjectName && this.isHidden == null && !this.newProjectIcon) {
      console.log('return');
      this.editMode = false;
      return;
    }
    const updatedLock = await this.api
      .updateLock({
        id: this.projectId,
        projectName: this.newProjectName,
        projectIcon: this.newProjectIcon,
        isHidden: this.isHidden,
      })
      .toPromise();
    this.lockInfo = updatedLock;
    this.editMode = false;
  }

  public depositFunds(): void {
    this.web3
      .depositFundsToLock(
        this.lockInfo.contractAddress,
        this.lockInfo.tokenAddress,
        new BigNumber(this.depositValue)
      )
      .then(
        () => {
          this.popupService.successMessage('deposited');
          this.getTokenAdditionalInfo();
        },
        () => this.popupService.errorMessage('fail')
      );
  }

  public emergencyWithdraw(): void {
    this.web3
      .emergencyWithdraw(
        this.lockInfo.version,
        this.lockInfo.contractAddress,
        this.lockedBalance
      )
      .then(
        () => {
          this.popupService.successMessage('withdraw');
          this.getTokenAdditionalInfo();
        },
        () => this.popupService.errorMessage('fail')
      );
  }

  public async exportRefundRequests(): Promise<void> {
    const result = await this.web3.exportRefundRequests(
      this.lockInfo.contractAddress
    );

    const csvFile = this.papa.unparse([result]);

    const csvData = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'refund_requests.csv');
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
  }

  public pauseClaiming(): void {
    this.web3
      .setPause(
        this.lockInfo.version,
        this.lockInfo.tokenAddress,
        this.setPause,
        this.lockInfo.contractAddress
      )
      .then(
        () => this.popupService.successMessage('done'),
        () => this.popupService.errorMessage('fail')
      );
  }

  public onProjectIconSelected(event: any): void {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.newProjectIcon = event.target.result;
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
